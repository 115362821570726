<template>
  <div>
    <div class="mb-1">
      <h1>Cases I Manage</h1>
      <div>
        <hr>
        <div class="row mb-1">
          <div class="col-3">
            <!-- add button -->
            <div>
              <div class="col-10 pr-0 pl-0 D-flex justify-space-arround">
                <b-button
                  block
                  variant="primary"
                  style="
              justify-content: space-around;
              display: flex;
              border-radius: 0;
            "
                  @click="$router.push('/cases/add')"
                >
                  <span>+ Add Case</span>
                </b-button>
              </div>
            </div>
          </div>
          <div class="col-5"/>
          <div class="col-4">
            <b-form-group>
              <div class="d-flex align-items-right">
                <b-form-input
                  v-model="searchFilterTerm"
                  placeholder="Filter"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: false
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: DateCreated -->
            <span
              v-if="props.column.field === 'DateCreated'"
              class="text-nowrap"
            >
              <span class="text-nowrap">{{ props.row.DateCreated | dateFormat }}</span>
            </span>

            <!-- Column: CaseDueDate -->
            <span
              v-else-if="props.column.field === 'CaseDueDate'"
              class="text-nowrap"
            >
              <span class="text-nowrap">{{ props.row.CaseDueDate | dateFormat }}</span>
            </span>

            <!-- Column: CompletedDate -->
            <span
              v-else-if="props.column.field === 'CompletedDate'"
              class="text-nowrap"
            >
              <span class="text-nowrap">{{ props.row.CompletedDate | dateFormat }}</span>
            </span>

            <!-- Column: Case -->
            <span v-else-if="props.column.field === 'FileNumber'">
              <router-link
                :to="'cases/' + props.row.CaseID + '/detail'"
              >{{ props.row.FileNumber }}</router-link>&nbsp;
              <span v-if="UserType === 'User'">{{ props.row | mgrDisplay }}</span>
            </span>

            <!-- Column: Client -->
            <span v-else-if="props.column.field === 'ClientName' && UserType !== 'Client'">
              <router-link
                :to="'clients/view/' + props.row.ClientID"
              >{{ props.row.ClientName }}</router-link>
            </span>

            <!-- Column: Subject -->
            <span v-else-if="props.column.field === 'Subject' && UserType !== 'Client'">
              <router-link
                :to="'subject/' + props.row.SubjectID "
              >{{ props.row.Subject }}</router-link>
            </span>

            <!-- Column: Logistics -->
            <span v-else-if="props.column.field === 'DatesToBeConducted'">
              {{ props.row.DatesToBeConducted }}
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'Action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item>
                    <span @click="viewCase(props.row.CaseID)">View Case</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>

          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Showing {{ displayStartIndex }} - {{ displayEndIndex }} of
                  {{ totalResultCount }}
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['100', '150', '200', '250', '300']"
                  class="mx-1"
                  @input="(value) => {
                    props.perPageChanged({ currentPerPage: value });
                    onPageSizeChanged({ currentPerPage: value });
                  }"
                />
                <span class="text-nowrap">per page</span>
              </div>
              <div>
                <b-pagination
                  v-model="currentPage"
                  :value="1"
                  :total-rows="totalResultCount"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                  @page-click="onPageChanged"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>
    <HomeInvestigator v-if="UserType === 'User'" />
  </div>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import APIService from "@core/utils/APIService";
import HomeInvestigator from "@/views/home/HomeInvestigator";
import {mapGetters} from "vuex";

const apiService = new APIService();

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BDropdown,
    BDropdownItem,
    HomeInvestigator,
  },
  filters: {
    dateFormat(val) {
      const d = new Date(val).getDate().toString();
      const m = (new Date(val).getMonth() + 1).toString();
      const y = new Date(val).getFullYear();
      if (val) return (m.length === 1 ? "0" + m : m) + "/" + (d.length === 1 ? "0" + d : d) + "/" + y;
    },
    mgrDisplay(val) {
      let text;
      text = val.IsAccountManager ? "Account Mgr, " : "";
      text = val.IsOperationsManager ? text + "Ops Mgr, " : text;
      text = val.IsPrimaryCaseAssistant ? text + "Research Mgr, " : text;
      text = val.IsSecondaryCaseAssistant ? text + "SIU Mgr, " : text;
      text = val.IsUSPManager ? text + "RCS Mgr, " : text;
      text = val.IsVendorManager ? text + "Vendor Mgr, " : text;
      text = val.IsCSRUser ? text + "CSR, " : text;
      text = val.IsBranchAM ? text + "BR AM, " : text;
      text = val.IsClientDealOwnerUser ? text + "Deal Owr, " : text;
      text = val.IsBranchDealOwnerUser ? text + "BR Deal Owr, " : text;
      text = text.substr(0, text.length - 2)
      return "(" + text + ")"
    }
  },
  data() {
    return {
      isDev: false,
      UserType: "",
      pageLength: 200,
      currentPage: 1,
      displayStartIndex: 1,
      displayEndIndex: 100,
      dir: false,
      totalResultCount: 0,
      awaitingSearch: false,
      IncludeClosedCases: false,
      columns: [
        {
          label: "Case",
          field: "FileNumber",
        },
        {
          label: "Client",
          field: "ClientName",
        },
        {
          label: "Subject",
          field: "Subject",
        },
        {
          label: "Created Date",
          field: "DateCreated",
          width: '9rem',
        },
        {
          label: "Due Date",
          field: "CaseDueDate",
          width: '9rem',
        },
        {
          label: "Completed Date",
          field: "CompletedDate",
          width: '9rem',
        },
        {
          label: "Operations Manager",
          field: "OperationsManager",
        },
        {
          label: "Location",
          field: "Location",
        },
        {
          label: "Logistics",
          field: "DatesToBeConducted",
        },
        {
          label: "Action",
          field: "Action",
        }
      ],
      rows: [],
      searchFilterTerm: "",
      status: [
        {
          1: "Active",
          2: "Disabled",
        },
        {
          1: "light-primary",
          2: "light-danger",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getCurrentScopes: "scopes/getCurrentScopes",
    }),
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  watch: {
    searchFilterTerm: function (val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.loadCasesPage(1);
          this.awaitingSearch = false;
        }, 750); // ms delay search debounce
      }
      this.awaitingSearch = true;
    },
    IncludeClosedCases: function (val) {
      this.loadCasesPage(1);
    }
  },
  async mounted() {
    this.loadCasesPage(1);
    this.UserType = localStorage.getItem("userType")
  },
  methods: {
    loadCasesPage(pageNumber) {
      if (this.UserType === 'Investigator') return;
      this.rows = [];

      var pageZeroIndex = pageNumber - 1;
      var startIndex = pageZeroIndex * this.pageLength;

      this.displayStartIndex = startIndex + 1;
      this.displayEndIndex = this.pageLength + this.displayStartIndex - 1;

      apiService
          .get("cases/dashboard?searchFilterTerm=" +
              encodeURIComponent(this.searchFilterTerm) +
              "&skip=" +
              startIndex +
              "&max=" +
              this.pageLength
          )
          .then((response) => {
            this.loadCaseResults(response.data);
          });
    },
    loadCaseResults(responseData) {
      this.rows = responseData.Cases;
      this.totalResultCount = responseData.TotalCount;
      this.displayEndIndex = this.rows.length + this.displayStartIndex - 1;
    },
    onPageChanged(bvEvent, page) {
      this.loadCasesPage(page);
    },
    onPageSizeChanged(newPageSize) {
      this.loadCasesPage(this.currentPage);
      this.pageLength = newPageSize.currentPerPage;
    },
    showToast(variant, position, timeout, data) {
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "InfoIcon",
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          }
      );
    },
    viewCase(CaseID) {
      this.$router.push('/cases/' + CaseID + '/detail');
    },
  },
}
</script>

<style>
.hold-btn:hover .hover-display {
  transition-duration: 1.5s;
  border-radius: 0.5rem;
  opacity: 1;
}
.hold-btn {
  transition-duration: 1.5s;
  position: relative;
  top: 0;
  border-radius: 0.5rem;
  cursor: pointer;
  width: 2.5rem;
  height: 2.95rem;
  margin-right: 0.5rem;
}
.hover-display {
  position: relative;
  top: -2.2rem;
  padding-left: 2.3rem;
  padding-right: 0.5rem;
  opacity: 0;
}
.hold-btn:hover {
  transition-duration: 1.5s;
  background-color: #476dae;
  width: 12rem;
}
.img-hold {
  width: 2.5rem;
  background-color: #476dae;
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem 0.8rem;
  cursor: pointer;
}
</style>
